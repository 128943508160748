import {defineWebComponent} from '@domg-wc/common-utilities';
import {VlProzaMessage, VlProzaMessagePreloader} from '@domg-wc/components';
import {VoortoetsBaseElementOfType} from './util/voortoets-base.element';

export class VoortoetsProzaMessage extends VoortoetsBaseElementOfType(HTMLElement) {
  static get __DOMEIN() {
    return 'voortoets';
  }

  static get _observedAttributes() {
    return ['data-voortoets-code', 'data-voortoets-parameters'];
  }

  constructor() {
    super(`
      <style>
        :host {
          display: inline-block;
        }
      </style>
      <vl-proza-message data-vl-domain="${VoortoetsProzaMessage.__DOMEIN}"></vl-proza-message>
    `);
  }

  static async getMessage(code, parameters) {
    return VlProzaMessage.getMessage(VoortoetsProzaMessage.__DOMEIN, code,
        parameters);
  }

  static async getUnwrappedMessage(code, parameters) {
    return this.__unwrapHtml(await this.getMessage(code, parameters));
  }

  static async getProzaCodes(prefix) {
    return VlProzaMessagePreloader.getProzaCodes(VoortoetsProzaMessage.__DOMEIN,
        prefix);
  }

  _dataVoortoetsCodeChangedCallback() {
    this._element.setAttribute('data-vl-code', this.dataset.voortoetsCode);
  }

  _dataVoortoetsParametersChangedCallback(oldValue, newValue) {
    this._element.setAttribute('data-vl-parameters',
        this.dataset.voortoetsParameters);
  }

  static __unwrapHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
}

defineWebComponent(VoortoetsProzaMessage, 'voortoets-proza-message');
