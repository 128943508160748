import {defineWebComponent} from '@domg-wc/common-utilities';

import '@domg-wc/sections/footer';
import '@domg-wc/sections/header';
import '@domg-wc/sections/cookie-consent';
import '@domg-wc/components/proza-message';
import '@domg-wc/components/template';
import '@domg-wc/components/http-error-message';
import '@domg-wc/elements/grid';

import './voortoets-proza-message.js';
import './voortoets-header.js';
import './voortoets-footer.js';
import {VoortoetsBaseElementOfType} from './util/voortoets-base.element';

class VoortoetsTemplate extends VoortoetsBaseElementOfType(HTMLElement) {
  static __httpErrorTemplate(status) {
    return `
      <section is="vl-region">
        <div is="vl-layout">
          <vl-http-${status}-message></vl-http-${status}-message>
        </div>
      </section>
    `;
  }

  constructor() {
    super(`
            <style>
                vl-template {
                    display:block;
                }
            </style>
            <vl-template>
                <voortoets-header slot="header"></voortoets-header>
                <div slot="main" id="main">
                    <vl-cookie-consent></vl-cookie-consent>
                    <vl-proza-message-preloader data-vl-domain="voortoets"></vl-proza-message-preloader>
                    <slot></slot>
                </div>
                <voortoets-footer slot="footer"></voortoets-footer>
            </vl-template>
        `);
    this.__init();
  }

  get _vlTemplate() {
    return this.shadowRoot.querySelector('vl-template');
  }

  get __header() {
    return this._vlTemplate.querySelector('voortoets-header');
  }

  get __footer() {
    return this._vlTemplate.querySelector('voortoets-footer');
  }

  get __mainSlot() {
    return this._shadow.querySelector('[slot="main"]');
  }

  showHttpError(httpError) {
    this.__mainSlot.innerHTML = VoortoetsTemplate.__httpErrorTemplate(httpError.response.status);
  }

  isHttpErrorShown(httpError) {
    return !!this.shadowRoot.querySelector('vl-http-' + httpError.response.status + '-message');
  }

  __init() {
    this.__onHeaderAndFooterReady(() => {
      this.__dispatchReadyEvent();
    });
  }

  __onHeaderAndFooterReady(callback) {
    const headerReady = new Promise((resolve) =>
      this.__header.addEventListener('ready', () => resolve()),
    );
    const footerReady = new Promise((resolve) =>
      this.__footer.addEventListener('ready', () => resolve()),
    );
    Promise.all([headerReady, footerReady]).then(() => callback());
  }

  __dispatchReadyEvent() {
    const header = this.__header;
    const footer = this.__footer;
    this.dispatchEvent(new CustomEvent('ready', {
      detail: {
        headerOffsetHeight: header ? header.offsetHeight : 0,
        footerOffsetHeight: footer ? footer.offsetHeight : 0,
      }}));
  }
}

defineWebComponent(VoortoetsTemplate, 'voortoets-template');
