import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/sections/footer';
import {VoortoetsBaseElementOfType} from './util/voortoets-base.element';
import VoortoetsConfiguratieService from './voortoets-configuratie-service';

class VoortoetsFooter extends VoortoetsBaseElementOfType(HTMLElement) {
  static __template(uuid, isDevelopment) {
    return `<vl-footer 
                data-vl-identifier="${uuid}"
                ${isDevelopment ? 'data-vl-development' : ''}></vl-footer>`;
  }

  constructor() {
    super();
    this.__init();
  }

  get offsetHeight() {
    return this.__vlwFooter.offsetHeight || 0;
  }

  get __vlwFooter() {
    return document.querySelector('.vlw__footer');
  }

  __init() {
    this.__appendTemplate().then(() => this.__awaitWidget());
  }

  async __appendTemplate() {
    const properties = await VoortoetsConfiguratieService.getProperties();
    const uuid = properties['footerUUID'];
    const isDevelopment = properties['headerFooterDevelopmentMode'];
    if (uuid) {
      this.appendChild(this._template(VoortoetsFooter.__template(uuid, isDevelopment)));
    }
  }

  async __awaitWidget() {
    const observer = new MutationObserver(() => {
      if (this.__vlwFooter) {
        this.dispatchEvent(new CustomEvent('ready'));
        observer.disconnect();
      }
    });
    observer.observe(document, {subtree: true, childList: true});
  };
}

defineWebComponent(VoortoetsFooter, 'voortoets-footer');
