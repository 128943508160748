export class HttpError extends Error {
  constructor(response) {
    super(`Response geeft aan dat er een fout is: ${response.statusText}`);
    this.__response = response;
  }

  get response() {
    return this.__response;
  }

  get clientError() {
    return this.response.status >= 400 && this.response.status <= 499;
  }
}

export default async function fetchJson(...args) {
  const response = await fetch(...args);
  if (response.ok) {
    return response.json();
  } else {
    throw new HttpError(response);
  }
}
