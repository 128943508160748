import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/elements/grid';
import '@domg-wc/components/typography';
import '@domg-wc/components/accordion';

import {VoortoetsBaseElementOfType} from './util/voortoets-base.element';
import './voortoets-proza-message.js';
import './landing/voortoets-module-effecten.js';

export class VoortoetsEffectenNietInRekening extends VoortoetsBaseElementOfType(HTMLElement) {
  constructor() {
    super(`
      <vl-accordion id="effecten-niet-in-rekening">
        <div slot="title">
          <voortoets-proza-message data-voortoets-code="effecten-niet-in-rekening.titel"></voortoets-proza-message>
        </div>
        <div is="vl-grid" data-vl-is-stacked>
          <div is="vl-column" data-vl-size="12">
            <vl-typography>
              <voortoets-module-effecten data-titel-module="effecten-niet-in-rekening.oppervlaktewater.title" data-effecten-module="effecten-niet-in-rekening.oppervlaktewater.effecten"></voortoets-module-effecten>
            </vl-typography>
          </div>
        </div>
      </vl-accordion>
    `);
  }

  get __effectenNietInRekening() {
    return this.shadowRoot.querySelector('#effecten-niet-in-rekening');
  }

  prepareForPrint() {
    this.shadowRoot.querySelectorAll('vl-accordion').forEach((accordion) => accordion.open());
    return Promise.resolve();
  }
}

defineWebComponent(VoortoetsEffectenNietInRekening, 'voortoets-effecten-niet-in-rekening');
