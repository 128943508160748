import {awaitUntil, defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/elements/grid';
import '@domg-wc/elements/title';
import '@domg-wc/components/typography';
import '@domg-wc/components/content-header';


import {VoortoetsBaseElementOfType} from '../util/voortoets-base.element';
import '../voortoets-template.js';
import './voortoets-landing-acties.js';
import './voortoets-meer-informatie.js';
import './voortoets-effect-info.js';
import './voortoets-vragenlijst.js';

export class VoortoetsLandingspagina extends VoortoetsBaseElementOfType(HTMLElement) {
  constructor() {
    super(`
      <style>
        :host {
          flex: 1;
        }
      </style>
      <voortoets-template>
        <vl-content-header id="content-header">
          <img id="img-component" is="vl-image" slot="image" src="/img/default.jpg" srcset="/img/mobile.jpg 320w, /img/default.jpg 1024w, /img/wide.jpg 1600w" alt="">
          <a slot="context-link" href="${window.location.href}">
            <voortoets-proza-message data-voortoets-code="landingspagina.content-header.context"></voortoets-proza-message>
          </a>
          <a slot="title-link" href="${window.location.href}">
            <voortoets-proza-message data-voortoets-code="landingspagina.content-header.title"></voortoets-proza-message>
          </a>
        </vl-content-header>
        <section is="vl-region">
          <div is="vl-layout">
            <div is="vl-grid" data-vl-is-stacked>
              <div is="vl-column">
                <h1 is="vl-h1" data-vl-no-space-bottom>
                  <voortoets-proza-message data-voortoets-code="landingspagina.title"></voortoets-proza-message>
                </h1>
              </div>
              <div is="vl-column">
                <vl-typography>
                  <hr/>
                </vl-typography>
              </div>
            </div>
          </div>
        </section>
        <section is="vl-region">
          <div is="vl-layout">
            <div is="vl-grid" data-vl-is-stacked>
              <div is="vl-column">
                <voortoets-effect-info></voortoets-effect-info>
              </div>
              <div is="vl-column">
                <voortoets-landing-acties></voortoets-landing-acties>
              </div>
              <div is="vl-column">
                <voortoets-vragenlijst></voortoets-vragenlijst>
              </div>
              <div is="vl-column">
                <voortoets-meer-informatie></voortoets-meer-informatie>
              </div>
            </div>
          </div>
        </section>
      </voortoets-template>
    `);
  }

  get _voortoetsVragenlijst() {
    return this.shadowRoot.querySelector('voortoets-vragenlijst');
  }

  async connectedCallback() {
    if (this.__urlBevatVerwijzingNaarVeelgesteldeVragen()) {
      await this.__scrollNaarVeelgesteldeVragen();
    }
  }

  __urlBevatVerwijzingNaarVeelgesteldeVragen() {
    return VoortoetsLandingspagina.__hash === '#veelgestelde-vragen';
  }

  static get __hash() {
    return new URL(window.location).hash;
  }

  async __scrollNaarVeelgesteldeVragen() {
    await awaitUntil(() => this._voortoetsVragenlijst.ready);
    this._voortoetsVragenlijst.scrollIntoView();
  }
}

defineWebComponent(VoortoetsLandingspagina, 'voortoets-landingspagina');
