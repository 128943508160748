import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/elements/grid';
import '@domg-wc/elements/title';
import '@domg-wc/elements/doormat';

import {VoortoetsBaseElementOfType} from '../util/voortoets-base.element';
import '../voortoets-template.js';
import '../voortoets-proza-message.js';

export class VoortoetsLandingActies extends VoortoetsBaseElementOfType(HTMLElement) {
  constructor() {
    super(`
      <style>
      </style>
      <div>
        <h2 is="vl-h2"><voortoets-proza-message data-voortoets-code="landing-acties.titel"></voortoets-proza-message></h2>
        <div is="vl-grid" data-vl-is-stacked>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <a is="vl-doormat" href="/invoerpagina.html">
              <h2 is="vl-doormat-title">
                <voortoets-proza-message data-voortoets-code="landing-acties.start-voortoets.titel"></voortoets-proza-message>
              </h2>
              <div is="vl-doormat-text">
                <voortoets-proza-message data-voortoets-code="landing-acties.start-voortoets.tekst"></voortoets-proza-message>
              </div>
            </a>
          </div>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <a is="vl-doormat" href="/hervattenpagina.html">
              <h2 is="vl-doormat-title">
                <voortoets-proza-message data-voortoets-code="landing-acties.hervat-voortoets.titel"></voortoets-proza-message>
              </h2>
              <div is="vl-doormat-text">
                <voortoets-proza-message data-voortoets-code="landing-acties.hervat-voortoets.tekst"></voortoets-proza-message>
              </div>
            </a>
          </div>
          </div>
        </div>
    `);
  }
}

defineWebComponent(VoortoetsLandingActies, 'voortoets-landing-acties');
