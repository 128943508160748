import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/components/alert';
import '@domg-wc/components/typography';
import '@domg-wc/components/accordion';
import '@domg-wc/elements/grid';
import '@domg-wc/elements/title';

import {VoortoetsBaseElementOfType} from '../util/voortoets-base.element';
import '../voortoets-proza-message.js';
import './voortoets-module-info-tiles.js';
import '../voortoets-geen-uitspraak.js';

export class VoortoetsEffectInfo extends VoortoetsBaseElementOfType(HTMLElement) {
  constructor() {
    super(`
      <div id = "content-activity">
        <div is="vl-grid" data-vl-is-stacked>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <h2 is='vl-h2' id="effect-activiteit-titel">
              <voortoets-proza-message data-voortoets-code="landingspagina.effect-activiteit-titel"></voortoets-proza-message>
            </h2>
          </div>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <vl-typography id="effect-activiteit-content">
              <p><voortoets-proza-message data-voortoets-code="landingspagina.effect-activiteit-content"></voortoets-proza-message></p>
            </vl-typography>
          </div>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <vl-alert id="effect-activiteit-info-alert" data-vl-type="info">
              <span slot="title"><voortoets-proza-message data-voortoets-code="landingspagina.info-titel"></voortoets-proza-message></span>
              <voortoets-proza-message data-voortoets-code="landingspagina.info"></voortoets-proza-message>
            </vl-alert>
          </div>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <vl-accordion id="effecten-in-rekening">
              <div slot="title">
                <voortoets-proza-message data-voortoets-code="landingspagina.effecten-in-rekening-titel"></voortoets-proza-message>
              </div>
              <div id="effecten-in-rekening-tekst">
                <voortoets-proza-message data-voortoets-code="landingspagina.effecten-in-rekening-tekst"></voortoets-proza-message>
              </div>
              <voortoets-module-info-tiles></voortoets-module-info-tiles>
            </vl-accordion>
          </div>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            <voortoets-geen-uitspraak></voortoets-geen-uitspraak>
          </div>
        </div>
      </div>
    `);
  }


  get __effectActiviteitTitel() {
    return this.shadowRoot.querySelector('#effect-activiteit-titel');
  }

  get __effectActiviteitContent() {
    return this.shadowRoot.querySelector('#effect-activiteit-content');
  }

  get __effectActiviteitInfoAlert() {
    return this.shadowRoot.querySelector('#effect-activiteit-info-alert');
  }

  get __effectenInRekening() {
    return this.shadowRoot.querySelector('#effecten-in-rekening');
  }

  get __effectenNietInRekening() {
    return this.shadowRoot.querySelector('voortoets-geen-uitspraak');
  }
}

defineWebComponent(VoortoetsEffectInfo, 'voortoets-effect-info');
