import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/elements/title';
import '@domg-wc/components/typography';
import '@domg-wc/components/accordion';
import '@domg-wc/elements/grid';


import {VoortoetsBaseElementOfType} from './util/voortoets-base.element';
import './voortoets-proza-message.js';
import './voortoets-effecten-niet-in-rekening.js';

export class VoortoetsGeenUitspraak extends VoortoetsBaseElementOfType(HTMLElement) {
  constructor() {
    super(`
    <h3 is="vl-h3" id="titel"><voortoets-proza-message data-voortoets-code="geen-uitspraak.title"></voortoets-proza-message></h3>
    <div is="vl-grid" data-vl-is-stacked>
      <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
        <voortoets-effecten-niet-in-rekening></voortoets-effecten-niet-in-rekening>
      </div>
      <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
        <vl-accordion id="soorten-vogelrichtlijnen-ven">
          <div slot="title">
            <voortoets-proza-message data-voortoets-code="geen-uitspraak.soorten-vogelrichtlijnen-ven.title"></voortoets-proza-message>
          </div>
          <div is="vl-grid" data-vl-is-stacked>
            <div is="vl-column" data-vl-size="12">
              <vl-typography>
                <voortoets-proza-message data-voortoets-code="geen-uitspraak.soorten-vogelrichtlijnen-ven.content"></voortoets-proza-message>
              </vl-typography>
            </div>
          </div>
        </vl-accordion>
      </div>
      <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
        <vl-accordion id="cumulatieveEffecten">
          <div slot="title">
            <voortoets-proza-message data-voortoets-code="geen-uitspraak.cumulatieve-effecten.title"></voortoets-proza-message>
          </div>
          <div is="vl-grid" data-vl-is-stacked>
            <div is="vl-column" data-vl-size="12">
              <vl-typography>
                <voortoets-proza-message data-voortoets-code="geen-uitspraak.cumulatieve-effecten.content"></voortoets-proza-message>
              </vl-typography>
            </div>
          </div>
        </vl-accordion>
      </div>
    </div>
    `);
  }

  get __titel() {
    return this.shadowRoot.querySelector('#titel');
  }

  get __voortoetsEffectenNietInRekening() {
    return this.shadowRoot.querySelector('voortoets-effecten-niet-in-rekening');
  }

  get __soortenVogelRichtlijnVenInfo() {
    return this.shadowRoot.querySelector('#soorten-vogelrichtlijnen-ven');
  }

  get __cumulatieveEffecten() {
    return this.shadowRoot.querySelector('#cumulatieveEffecten');
  }

  prepareForPrint() {
    return Promise.all([this.__prepareForPrint(), this.__voortoetsEffectenNietInRekening.prepareForPrint()]);
  }

  __prepareForPrint() {
    this.shadowRoot.querySelectorAll('vl-accordion').forEach((accordion) => accordion.open());
    return Promise.resolve();
  }
}

defineWebComponent(VoortoetsGeenUitspraak, 'voortoets-geen-uitspraak');
