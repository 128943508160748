import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/components/typography';

import {VoortoetsBaseElementOfType} from '../util/voortoets-base.element';
import '../voortoets-proza-message.js';

export class VoortoetsModuleEffecten extends VoortoetsBaseElementOfType(HTMLElement) {
  connectedCallback() {
    this.shadow(`
      <style>
        .effecten {
          display: block;
        }
      </style>
      <div id="content">
        <vl-typography>
          <voortoets-proza-message data-voortoets-code="${this._titelModule}"></voortoets-proza-message>
        </vl-typography>
        <voortoets-proza-message class="effecten" data-voortoets-code="${this._effectenModule}"></voortoets-proza-message>
      </div>
    `);
  }

  get _titelModule() {
    return this.dataset.titelModule;
  }

  get _effectenModule() {
    return this.dataset.effectenModule;
  }

  get __title() {
    return this.shadowRoot.querySelector('vl-typography');
  }

  get __content() {
    return this.shadowRoot.querySelector('#content');
  }
};


defineWebComponent(VoortoetsModuleEffecten, 'voortoets-module-effecten');
