import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/elements/grid';
import '@domg-wc/elements/title';

import {VoortoetsBaseElementOfType} from '../util/voortoets-base.element';
import {VoortoetsProzaMessage} from '../voortoets-proza-message';
import '../voortoets-template.js';

export class VoortoetsVragenlijst extends VoortoetsBaseElementOfType(HTMLElement) {
  async connectedCallback() {
    this.shadow(`
      <div>
        <h2 is="vl-h2"><voortoets-proza-message data-voortoets-code="landingspagina.vragenlijst.titel"></voortoets-proza-message></h2>
        <div is="vl-grid" data-vl-is-stacked>
          <div is="vl-column" data-vl-size="12" data-vl-medium-size="12">
            ${(await this._prozaVraagEnAntwoorden()).map((prozaVraagEnAntwoord) =>
    `<vl-accordion id='landingspagina-vragenlijst-vraag-${prozaVraagEnAntwoord.nummer}'>
        <div slot="title">
          <voortoets-proza-message data-voortoets-code='${prozaVraagEnAntwoord.vraagProzaCode}'></voortoets-proza-message>
        </div>
        <voortoets-proza-message data-voortoets-code='${prozaVraagEnAntwoord.antwoordProzaCode}'></voortoets-proza-message>
      </vl-accordion>`).join('')}
          </div>
        </div>
    `);
    this.__ready = true;
  }

  get ready() {
    return this.__ready;
  }

  async _prozaVraagEnAntwoorden() {
    const prozaCodes = await VoortoetsProzaMessage.getProzaCodes('landingspagina.vragenlijst');
    const vragenProzaCodes = prozaCodes.filter((prozaCode) => prozaCode.includes('vraag'));
    return vragenProzaCodes.map((vraagProzaCode) => {
      const nummer = vraagProzaCode.split('.').pop();
      const antwoordProzaCode = prozaCodes.find(((prozaCode) => prozaCode.endsWith(`antwoord.${nummer}`)));
      return this._vraagEnAntwoord(nummer, vraagProzaCode, antwoordProzaCode);
    }).filter((obj) => obj.antwoordProzaCode !== undefined)
        .sort((obj1, obj2) => (obj1.nummer > obj2.nummer) ? 1 : -1);
  }

  _vraagEnAntwoord(nummer, vraagProzaCode, antwoordProzaCode) {
    return {
      nummer: Number(nummer),
      vraagProzaCode: vraagProzaCode,
      antwoordProzaCode: antwoordProzaCode,
    };
  }
}

defineWebComponent(VoortoetsVragenlijst, 'voortoets-vragenlijst');
