import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/elements/grid';
import '@domg-wc/elements/title';
import '@domg-wc/elements/doormat';

import {VoortoetsBaseElementOfType} from '../util/voortoets-base.element';
import '../voortoets-template.js';
import '../voortoets-proza-message.js';

export class VoortoetsMeerInformatie extends VoortoetsBaseElementOfType(HTMLElement) {
  constructor() {
    super(`
      <div>
        <h2 is="vl-h2"><voortoets-proza-message data-voortoets-code="landingspagina.meer-informatie.titel"></voortoets-proza-message></h2>
        <div is="vl-grid" data-vl-is-stacked>
          <div is="vl-column" data-vl-size="6" data-vl-medium-size="6">
            <a is="vl-doormat" href="https://www.natura2000.vlaanderen.be/passendebeoordeling">
              <h2 is="vl-doormat-title">
                <voortoets-proza-message data-voortoets-code="landingspagina.meer-informatie.passende-beoordeling.titel"></voortoets-proza-message>
              </h2>
              <div is="vl-doormat-text">
                <voortoets-proza-message data-voortoets-code="landingspagina.meer-informatie.passende-beoordeling.tekst"></voortoets-proza-message>
              </div>
            </a>
          </div>
          <div is="vl-column" data-vl-size="6" data-vl-medium-size="6">
            <a is="vl-doormat" href="https://www.natura2000.vlaanderen.be/">
              <h2 is="vl-doormat-title">
                <voortoets-proza-message data-voortoets-code="landingspagina.meer-informatie.natura2000.titel"></voortoets-proza-message>
              </h2>
              <div is="vl-doormat-text">
                <voortoets-proza-message data-voortoets-code="landingspagina.meer-informatie.natura2000.tekst"></voortoets-proza-message>
              </div>
            </a>
          </div>
          </div>
        </div>
    `);
  }
}

defineWebComponent(VoortoetsMeerInformatie, 'voortoets-meer-informatie');
