import {BaseLitElement, defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/components/info-tile';
import {vlElementsStyle} from '@domg-wc/elements';
import {html, nothing} from 'lit';
import {when} from 'lit-html/directives/when.js';
import '../voortoets-proza-message.js';
import VoortoetsConfiguratieService from '../voortoets-configuratie-service';

export class VoortoetsModuleInfoTiles extends BaseLitElement {
  static get properties() {
    return {
      _modules: {type: Object},
    };
  }

  static get styles() {
    return vlElementsStyle;
  }
  render() {
    return when(this._modules, () => html`
      <div is="vl-grid" data-vl-is-stacked data-vl-v-stretch>
        ${Object.keys(this._modules).map((module) => html`
          <div is="vl-column" data-vl-size="4" data-vl-medium-size="4">
            <vl-info-tile custom-css=".vl-info-tile {height: 100%; background-color: #E2EFD9;}">
              <span slot="title"><voortoets-proza-message data-voortoets-code="module.${module}"></voortoets-proza-message></span>
              <span slot="content"><ul>
                ${this._modules[module].map((effect) => effect.module ? html`
                  <li><voortoets-proza-message data-voortoets-code="module.${module}.${effect.naam}"></voortoets-proza-message></li>` : nothing)}
              </ul></span>
            </vl-info-tile>
          </div>
        `)}
      </div>
    `);
  }

  firstUpdated() {
    this.__getModules();
  }

  async __getModules() {
    const ingrepenConfig = await VoortoetsConfiguratieService.getIngrepenConfiguratie();
    this._modules = ingrepenConfig.effecten.reduce((modules, effect) => {
      if (!effect.module || this.__isActiefEffect(effect, ingrepenConfig)) {
        const module = effect.module ? effect.module : effect.naam;
        if (!modules[module]) {
          modules[module] = [];
        }
        modules[module].push(effect);
      }
      return modules;
    }, {});
  }

  __isActiefEffect(effect, ingrepenConfig) {
    const actieveModules = ingrepenConfig.activiteitenGroepen
        .flatMap((groep) => groep.activiteitNamen)
        .map((naam) => ingrepenConfig.activiteiten.find((activiteit) => activiteit.naam === naam))
        .map((activiteit) => ingrepenConfig.actieveIngrepen.find((ingreep) => ingreep.type === activiteit.ingreep));
    const actieveEffecten = actieveModules.flatMap((module) => module.module === effect.module ? module.effecten : []);
    return actieveEffecten.find((actiefEffect) => actiefEffect === effect.naam) != null;
  }
}

defineWebComponent(VoortoetsModuleInfoTiles, 'voortoets-module-info-tiles');
