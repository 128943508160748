import fetchJson from './voortoets-fetch.js';

export class VoortoetsConfiguratieService {
  static getProperties() {
    if (!VoortoetsConfiguratieService._config) {
      VoortoetsConfiguratieService._config = fetchJson('/config');
    }
    return VoortoetsConfiguratieService._config;
  }

  static getIngrepenConfiguratie() {
    if (!VoortoetsConfiguratieService._ingrepenConfig) {
      VoortoetsConfiguratieService._ingrepenConfig = fetchJson('/config/ingrepen');
    }
    return VoortoetsConfiguratieService._ingrepenConfig;
  }

  static getStoffen() {
    if (!VoortoetsConfiguratieService._stoffen) {
      VoortoetsConfiguratieService._stoffen = fetchJson('/p/voortoetsberekening/stoffen');
    }
    return VoortoetsConfiguratieService._stoffen;
  }
}

export default VoortoetsConfiguratieService;
