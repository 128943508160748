import {defineWebComponent} from '@domg-wc/common-utilities';
import '@domg-wc/sections/header';

import VoortoetsConfiguratieService from './voortoets-configuratie-service';
import {VoortoetsBaseElementOfType} from './util/voortoets-base.element';

export class VoortoetsHeader extends VoortoetsBaseElementOfType(HTMLElement) {
  static __template(uuid, isDevelopment) {
    return `<vl-header 
                data-vl-identifier="${uuid}" 
                data-vl-login-url="/oauth2/authorization/omgeving" 
                ${isDevelopment ? 'data-vl-development' : ''}></vl-header>`;
  }

  constructor() {
    super();
    this.__init();
  }

  get offsetHeight() {
    return this.__vlGlobalHeader.offsetHeight || 0;
  }

  get __vlGlobalHeader() {
    return document.querySelector('#vl-global-header');
  }

  __init() {
    this.__appendTemplate().then(() => this.__awaitWidget());
  }

  async __appendTemplate() {
    const properties = await VoortoetsConfiguratieService.getProperties();
    const uuid = properties['headerUUID'];
    const isDevelopment = properties['headerFooterDevelopmentMode'];
    if (uuid) {
      this.appendChild(this._template(VoortoetsHeader.__template(uuid, isDevelopment)));
    }
  }

  async __awaitWidget() {
    const observer = new MutationObserver(() => {
      if (this.__vlGlobalHeader) {
        this.dispatchEvent(new CustomEvent('ready'));
        observer.disconnect();
      }
    });
    observer.observe(document, {childList: true, subtree: true});
  };
}

defineWebComponent(VoortoetsHeader, 'voortoets-header');
