import {BaseElementOfType} from '@domg-wc/common-utilities';
import {vlElementsStyle} from '@domg-wc/elements';

const sheets = vlElementsStyle.map(({styleSheet}) => styleSheet);

export const VoortoetsBaseElementOfType = (SuperClass) => {
  return class VoortoetsBaseElement extends BaseElementOfType(SuperClass) {
    shadow(html) {
      super.shadow(html);
      this._appendVlElementsStyle();
    }

    _appendVlElementsStyle() {
      this.shadowRoot.adoptedStyleSheets.push(...sheets);
    }
  };
};
